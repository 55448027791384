
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        







































































































































































































































































































































































/* stylelint-disable selector-max-combinators, selector-max-compound-selectors, max-nesting-depth, declaration-no-important */
.product-hero {
  @include bg-color;

  position: relative;
  overflow: hidden;

  @include mq(l) {
    margin-top: $spacing * 5;
    padding-bottom: $spacing * 6;
  }
}

.product-hero__inner {
  position: relative;
  display: flex;
  flex-direction: column;

  .is-business & {
    padding-top: 10rem;
  }

  @include mq(l) {
    flex-direction: row;
    padding-top: $header-height;

    .is-business & {
      padding-top: 20rem;
    }
  }
}

.product-hero__intro {
  @include mq(m) {
    width: col(10);
  }

  @include mq(l) {
    width: col(5);
    margin-right: col(1);
  }
}

.product-hero__intro__logo {
  max-width: 30rem;
  max-height: 10rem;
}

.product-hero__intro__text {
  a {
    color: $c-pink-medium;
  }
}

.product-hero__promo {
  margin-top: $spacing * 3.5;

  @include mq($until: l) {
    @include center-x;

    bottom: -$spacing * 10;
  }
}

// B2S promo temp may be removed after
.product-hero__promo-picture {
  position: relative;
  bottom: auto;
  left: auto;
  display: flex;
  align-items: center;
  max-width: 60rem;
  padding: 3rem 3rem 3rem 0;
  border: 1px solid $c-orange;
  border-radius: $border-radius;
  transform: none;
}

.product-hero__promo__label {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 3rem;
  transform: translateY(-50%);
}

.product-hero__promo__picture {
  position: relative;
  align-self: stretch;
  overflow: hidden;
  width: 100%;
  max-width: 22rem;
  margin: -3rem 2rem -3rem 0;

  img {
    @include image-fit(contain, bottom);

    top: auto;
  }
}

.product-hero__promo__content__title {
  @extend %fw-black;

  display: block;
  font-family: $ff-alt;
  font-size: 1.8rem;
  line-height: 1.1;

  ::v-deep {
    strong {
      @extend %fw-black;

      display: block;
      margin-bottom: 0.5rem;
      // color: $c-pink-medium;
    }

    small {
      @extend %fw-normal;

      display: block;
      font-size: 1.3rem;
      line-height: 1.1;

      @include mq(m) {
        font-size: 1.5rem;
      }
    }
  }

  @include mq(m) {
    font-size: 2.4rem;
  }
}

.product-hero__promo__content__text {
  margin-top: 1rem;
  font-size: 1.3rem;
}

.product-hero__promo__content__link {
  margin-top: 1rem;
  font-size: 1.5rem !important;
}

.product-hero__intro__consumption {
  display: block;
  margin-top: $spacing * 2;

  .is-business & {
    margin-bottom: $spacing;
  }
}

.product-hero__offer {
  position: relative;
  // display: inline-flex;
  // flex-direction: column;
  // align-items: center;

  @include mq(l) {
    flex: 1;
    // margin-top: $spacing * 7.5;
    .is-mobile,
    .is-trio,
    .is-quatro {
      .product-card__tooltip {
        display: block;
      }
    }
  }
}

.product-hero__offer__speedlines {
  @include get-all-space;

  top: -10%;
  left: -10%;
  width: 120%;
  height: 120%;
}

.product-hero__offer__link {
  position: relative;
  z-index: layer(high);
  width: 100%;
  margin-bottom: $spacing * 2;
  text-align: center;

  @include mq(l) {
    margin-top: $spacing * 2;
    margin-bottom: 0;
  }
}

.product-hero__switcher {
  margin-top: $spacing;
}

.product-hero__client {
  margin-top: $spacing;
  border-top: 1px solid $c-blue-lightest;
}

.product-hero__client__title {
  margin: 0;
  margin-top: $spacing;
}

.product-hero__client__excerpt {
  margin: 0;
  margin-top: $spacing / 2;
}

.product-hero__client__link-outer {
  display: flex;
  margin-top: $spacing / 2;
}

.product-hero__client__link-inner {
  & + & {
    margin-left: $spacing;
  }
}

.product-hero__client__link {
  @extend %fw-bold;

  position: relative;
}

.product-hero__offer__card {
  margin: $spacing * 3 auto;

  @include mq(l) {
    margin: 0 auto;
  }
}

.product-hero__guide {
  max-width: 53rem;
  margin: 0 auto $spacing * 3;
  text-align: center;

  @include mq(m) {
    margin: 0 auto $spacing * 5;
    padding-right: 0;
    padding-left: 0;
  }
}

.product-hero__guide__text,
.product-hero__guide__title {
  margin: 0 0 $spacing * 1.25;

  @include mq(m) {
    margin: 0 0 $spacing * 1.5;
  }
}

.product-hero__guide__title {
  font-size: 4rem;

  @include mq(xl) {
    font-size: 4.8rem;
  }
}

.product-hero__guide__text {
  @include mq(l) {
    margin: 0 0 $spacing * 2;
    font-size: 1.8rem;
  }
}

.product-hero__promo-banner {
  margin-top: 8.6rem;

  .promo-banner {
    background: $c-promo;
  }

  .promo-banner__content {
    max-width: none;
  }

  .promo-banner__title {
    font-size: 1.4em / $body-text-s * 1em;
    line-height: 1.25;

    @include mq(m) {
      font-size: 1.8em / $body-text-m * 1em;
    }

    @include mq(l) {
      font-size: 2.2em / $body-text-l * 1em;
    }
  }

  .promo-banner__txt {
    font-size: 0.8em;
  }

  .promo-banner__image {
    @include aspect-ratio(468, 340);

    position: absolute;
  }

  @include mq(s) {
    .promo-banner__tag {
      left: 2.7rem;
    }

    .promo-banner__fig {
      margin-left: 0;
    }
  }

  @include mq(m) {
    .promo-banner {
      padding: 0;
    }

    .promo-banner__content {
      margin-left: 20rem;
      padding: 3rem;
    }

    .promo-banner__image,
    .promo-banner__video {
      width: 20rem;
    }
  }

  @include mq(l) {
    .promo-banner__fig {
      display: flex;
    }

    .promo-banner__content {
      margin: 0;
    }

    .promo-banner__image,
    .promo-banner__video {
      display: none;
    }
  }

  @include mq(xl) {
    .promo-banner__content {
      width: 60%;
      margin-left: auto;
    }

    .promo-banner__image,
    .promo-banner__video {
      display: block;
      width: 24rem;
    }
  }

  @include mq(xxl) {
    .promo-banner {
      min-height: 16rem;
    }
  }
}

.product-hero__stamp {
  margin-top: $spacing * 2;
  padding: $spacing 0;
}

.product-hero__stamp-condition {
  position: relative;
  margin-top: $spacing * 0.5;
  color: $c-gray;
  font-size: 1.2rem;
}
