
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        











































































































































































.product-mobile-outer,
[class*='product-mobile-outer--'] {
  @include bg-color;

  position: relative;
  padding: $spacing * 2 0;

  @include mq('m') {
    padding: $spacing * 3 0;
  }

  @include mq('xl') {
    padding: $spacing * 6 0;
  }
}

.product-mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq(l) {
    flex-direction: row-reverse;
  }
}

.product-mobile__wrapp {
  @include mq(l) {
    width: col(6);
    padding-left: col(1);
  }
}

.product-mobile__icon-outer {
  @extend %text-uppercase;

  display: flex;
  align-items: center;
}

.product-mobile__icon {
  height: 4rem;
  margin-right: $spacing;
}

.product-mobile__content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.product-mobile__content__title {
  margin: 0;
  margin-top: $spacing;
}

.product-mobile__content__excerpt {
  @extend %fw-light;

  margin: 0;
  margin-top: $spacing * 1.5;
}

.product-mobile__content__terms {
  margin-top: $spacing * 1.5;
  font-size: 1.2rem;
}

.product-mobile__information {
  @extend %fw-medium;
  margin-top: $spacing;
  color: $c-gray-light;
  font-family: $ff-default;
  font-size: 1.5rem;

  span {
    @extend %text-uppercase;
  }
}

.product-mobile__data {
  @extend %box-shadow;

  position: relative;
  margin-top: $spacing / 2;
  border-radius: 1rem;

  @include mq(m) {
    margin-top: $spacing;
  }
}

.product-mobile__data__include,
.product-mobile__data__option {
  padding: $spacing * 1.5;

  @include mq(m) {
    padding: $spacing * 2;
  }
}

.product-mobile__data__include {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $c-gray-lightest;
  border-bottom: 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.product-mobile__data__include__amount {
  margin: 0;
  color: $c-pink-medium;
  font-family: $ff-alt;

  strong {
    @extend %fw-bold;
  }

  .product-mobile-outer.is-business & {
    @extend %fw-bold;

    font-size: 2.4rem;
  }

  @include mq(m) {
    .product-mobile-outer.is-business & {
      font-size: 3rem;
    }
  }

  @include mq(xl) {
    .product-mobile-outer.is-business & {
      font-size: 3.6rem;
    }
  }
}

.product-mobile__data__include__label {
  @extend %fw-bold;
}

.product-mobile__data__option {
  @include bg-color;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.product-mobile__data__option__label {
  @extend %text-uppercase;
  @extend %fw-black;
  @include center-x;
  @include bg-color;

  top: 0;
  padding: $spacing / 2;
  font-family: $ff-alt;
  font-size: 1rem;
  letter-spacing: 1px;
  border-radius: 0.5rem;
  transform: translate(-50%, -50%);

  .is-business & {
    left: 75%;
  }
}

.product-mobile__data__option-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inner-col(4, 2);

  @include mq(xl) {
    width: inner-col(4, 1);
  }

  &:first-child {
    align-items: flex-start;
  }

  &:last-child {
    @include mq(l) {
      align-items: flex-end;
    }
  }
}

.product-mobile__data__option__amount {
  margin: 0;
  line-height: 1;

  strong {
    line-height: 1;
  }
}

.product-mobile__data__option__price {
  @extend %fw-bold;

  margin: 0;
}

.product-mobile__data__option__separator {
  @extend %fw-bold;

  position: relative;

  &::after,
  &::before {
    @include center-x;

    content: '';
    display: block;
    width: 0.1rem;
    height: 2rem;
    background-color: $white;
    opacity: 0.2;
  }

  &::before {
    top: 0;
    transform: translateY(-100%);
  }

  &::after {
    bottom: 0;
    transform: translateY(100%);
  }
}

.product-mobile__sim-number {
  @extend %fw-bold;
  margin-top: $spacing * 2;
  font-family: $ff-alt;
  font-size: 1.8rem;

  strong {
    @extend %fw-black;
  }

  @include mq(m) {
    margin-top: $spacing * 3;
    font-size: 2.4rem;
  }
}

.product-mobile__sim {
  display: flex;
  align-items: center;
  margin-top: $spacing;
  border: 1px solid $c-gray-lightest;
  padding: $spacing $spacing * 1.5;

  .is-business & {
    margin-top: $spacing;
  }

  @include mq(m) {
    margin-top: $spacing * 2;
  }

  @include mq(xl) {
    margin-top: $spacing * 2;
  }
}

.product-mobile__sim__icon {
  margin-right: $spacing;
}

.product-mobile__sim__price {
  strong {
    @extend %fw-bold;
  }
}

.product-mobile__speedlines {
  @include get-all-space;

  @include mq($until: m) {
    width: 120vw;
    height: 120vw;
    transform: translate(-15%, 20%);
  }
}

.product-mobile__sim__excerpt {
  margin-left: $spacing;
  color: $c-gray;
  font-family: $ff-default;
  font-size: 1.5rem;
  line-height: 1.3;

  span {
    @extend %fw-normal;
    @extend %text-uppercase;
  }
}

.product-mobile__picture-outer {
  position: relative;
  left: 0;
  width: col(10);
  height: 100%;
  @include mq(l) {
    position: absolute;
    top: 0;
    width: col(6);
  }
}

.product-mobile__picture {
  @include mq(xl) {
    @include image-fit(contain);
  }
}

.product-mobile__picture__promo {
  @include bg-color;
  @include center-y;
  z-index: 1;
  right: 0;
  width: 14rem;
  height: 14rem;
  color: $white;
  border-radius: 14rem;

  @include mq(l) {
    width: 22rem;
    height: 22rem;
    border-radius: 22rem;
  }
}

.product-mobile__picture__promo-inner {
  @extend %text-center;
  @include center-xy;
}
