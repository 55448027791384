
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        




























.table-desktop,
[class*='table-desktop--'] {
  @include mq(l) {
    &[class*='--solo'] {
      width: 80%;
      max-width: 100rem;
      margin: 0 auto;
    }
  }
}

.table__layout,
[class*='table__layout--'] {
  @include center-x;

  z-index: -1;
  top: 0;
  grid-gap: 1rem;
  width: 100%;
  height: 100%;
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 2;

  > div {
    border-radius: 1rem;
    box-shadow: 0 0 20px rgba($black, 0.2);
  }

  @include mq(m) {
    padding-top: $spacing * 4;
    padding-bottom: $spacing * 4;
  }

  @include mq(l) {
    &[class*='--solo'] {
      width: 80%;
      max-width: 100rem;
      margin: 0 auto;
    }
  }

  @include mq(xl) {
    display: grid;
    grid-template-rows: 27rem 1fr 5rem;
    grid-template-columns: 31% 34% 33.3%;
    padding-top: $spacing * 6;
    padding-bottom: $spacing * 6;

    .net-comparison-table & {
      padding-bottom: $spacing * 5;
    }

    &[class*='--solo'] {
      grid-template-columns: 1fr 35rem;
    }

    .is-solo & {
      grid-template-columns: 30% 22.5% 22.5% 22.5%;
    }

    .pack & {
      grid-template-rows: 34rem 1fr 12rem;
    }
  }
}

.table__layout__col,
[class*='table__layout__col--'] {
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column: 4;
  background: $white;

  &[class*='table__layout__col--'][class*='--solo'] {
    grid-column: 2;
  }
}

.table__layout__col:nth-child(2) {
  grid-column: 3;
  background: $white;
}

.table__layout__col:nth-child(3) {
  grid-column: 2;
  background: $white;
}

.table__layout__table {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 4;
  margin-right: 1rem;
  background: transparent;

  [class*='table__layout--'][class*='--solo'] & {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
