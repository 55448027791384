
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        








































































































































.product-fixe-outer,
[class*='product-fixe-outer--'] {
  @include bg-color;

  position: relative;
  padding: $spacing * 2 0;

  @include mq('m') {
    padding: $spacing * 3 0;
  }

  @include mq('xl') {
    padding: $spacing * 6 0;
  }
}

.product-fixe {
  @include mq(l) {
    display: flex;
    align-items: center;
  }
}

.product-fixe__wrapp {
  position: relative;
  z-index: 2;

  @include mq(m) {
    width: col(8);
    padding-right: col(1);
  }

  @include mq(xl) {
    width: col(7);
  }
}

.product-fixe__icon-outer {
  @extend %text-uppercase;

  display: flex;
  align-items: center;
}

.product-fixe__icon {
  width: 4rem;
  margin-right: $spacing;
}

.product-fixe__content {
  display: flex;
  flex-direction: column;
}

.product-fixe__content__terms {
  margin-top: $spacing * 1.5;
  font-size: 1.2rem;
}

.product-fixe__content__title {
  margin: 0;
  margin-top: $spacing;
}

.product-fixe__content__excerpt {
  @extend %fw-light;
  margin: 0;
  margin-top: $spacing * 1.5;

  span {
    @extend %text-uppercase;
  }
}

.product-fixe__content__include-outer {
  margin-top: $spacing * 2;

  @include mq(m) {
    margin-top: $spacing * 2.5;
  }
}

.product-fixe__content__include {
  @extend %text-uppercase;
}

.product-fixe__spec {
  @extend %box-shadow;

  position: relative;
  margin-top: $spacing;
  padding: $spacing * 2 0;
  background-color: $white;
  border-radius: 14px;

  @include mq(l) {
    display: flex;
    align-items: flex-start;
    margin-top: $spacing * 1.5;

    &::before {
      top: 50%;
    }
  }
}

.product-fixe__spec__item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 $spacing * 1.5;

  &:first-child {
    &::before {
      @include center-x;

      content: '';
      z-index: 2;
      bottom: 0;
      display: block;
      width: 3.6rem;
      height: 3.6rem;
      background-image: url('/shared/images/ui/more.svg');
      transform: translate(-50%, 50%);
    }
  }

  @include mq($until: l) {
    &:first-child {
      padding-bottom: $spacing * 2;
    }

    &:last-child {
      padding-top: $spacing * 2;
    }
  }

  @include mq(l) {
    flex-direction: column;
    justify-content: center;
    flex-basis: 50%;
    text-align: center;

    &:first-child {
      &::before {
        bottom: 50%;
        left: 100%;
        transform: translate(-50%, 50%);
      }
    }
  }

  & + & {
    border-top: 1px solid $c-gray-lightest;

    @include mq(l) {
      border-top: 0;
      border-left: 1px solid $c-gray-lightest;
    }
  }
}

.product-fixe__spec__icon {
  height: 5rem;
  fill: $c-pink-medium;

  @include mq($until: l) {
    flex-basis: 30%;
    margin-right: $spacing * 2;
  }
}

.product-fixe__spec__description {
  @include mq($until: l) {
    flex-basis: 70%;
  }
}

.product-fixe__spec__title {
  @extend %fw-bold;
  margin: 0;

  @include mq(l) {
    margin-top: $spacing;
    margin-bottom: $spacing * 0.25;
  }
}

.product-fixe__spec__subtitle {
  @extend %fw-normal;

  margin-bottom: 0;
}

.product-fixe__spec__excerpt {
  @extend %fw-medium;

  color: $gray-light;
  line-height: 1.5;

  span {
    @extend %text-uppercase;
  }
}

.product-fixe__information {
  @extend %fw-medium;

  margin-top: $spacing;
  color: $c-gray-light;
  font-family: $ff-default;
  font-size: 1.5rem;

  span {
    @extend %text-uppercase;
  }
}

.product-fixe__speedlines {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  margin-top: 0;

  @include mq($until: m) {
    display: none;
  }
}
