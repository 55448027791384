
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        
























.table {
  position: relative;
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 2;

  @include mq(m) {
    padding-top: $spacing * 4;
    padding-bottom: $spacing * 4;
  }

  @include mq(xl) {
    padding-top: $spacing * 6;
    padding-bottom: $spacing * 6;
  }
}
