
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        









































































































































































































































































































































































































































































































































































































































































































































































































































































































































































/* stylelint-disable selector-max-combinators, selector-max-compound-selectors, max-nesting-depth, declaration-no-important, no-descending-specificity */
.table-content,
[class*='table-content--'] {
  [class*='table-desktop--solo'] & {
    width: 100%;
  }
}

.table-desktop,
[class*='table-desktop--'] {
  .table-content__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }

  &[class*='--solo'] {
    .table-content__header__block {
      position: relative;

      @include mq($until: xl) {
        width: 100%;
      }

      @include mq(xl) {
        width: 35rem;
      }
    }
  }

  &.is-solo {
    .table-content__order__button-outer + .table-content__order__button-outer {
      margin-left: 1rem;
    }
  }
}

.table-content__header {
  @include mq($until: xl) {
    position: sticky;
    z-index: layer(high);
    top: 1rem;
    width: 100%;
    background-color: $white;
  }
}

.table-content__header__block {
  position: relative;
  width: 49%;
  margin-right: 1.5rem;
  border-radius: 1rem 1rem 0 0;

  .selection__products,
  .product-card__pricing {
    @include mq($until: m) {
      display: none;
    }
  }

  .is-solo & {
    width: 32%;

    @include mq(xl) {
      width: 23%;
      margin-right: 0;
    }
  }

  .product-card__message {
    @include mq($until: 'xl') {
      padding: 0;
    }
  }

  @include mq(xl) {
    width: 34%;
    height: 27rem;
    margin-right: 0;

    .pack & {
      height: 34rem;
    }
  }
}

// TODO : ok without btn
.table-content {
  margin-bottom: 13rem;
}

.table-content__data__item-inner {
  width: 100%;
}

.table-content__data-outer {
  overflow: hidden;
}

.table-content__data {
  width: 100%;
  font-family: $ff-alt;
  border-top: 1px solid $c-light-gray;
  border-collapse: collapse;
  border-spacing: 0;

  [class*='table-desktop--solo'] & {
    width: 100%;
  }

  @include mq(xl) {
    border: 0;
  }
}

.table-content__data__rgu {
  @extend %text-uppercase;
  @extend %fw-black;

  display: block;
  width: 100%;
  padding-top: $spacing;
  padding-bottom: $spacing;
  color: $c-pink-medium;
  font-size: 1.8rem;

  @include mq(xl) {
    padding-right: calc(68% + 3rem);
    text-align: right;

    .is-solo & {
      padding-right: 71%;
    }

    [class*='table-desktop--solo'] & {
      padding-right: 38rem;
    }
  }
}

.table-content__data__spec-outer {
  display: flex;
  align-items: center;

  @include mq($until: xl) {
    flex-wrap: wrap;
  }

  @include mq(xl) {
    &:nth-child(odd) {
      width: 100%;
      background: rgba(200, 200, 200, 0.1);
    }
  }
}

.table-content__data__spec,
.table-content__data__value {
  width: 50%;
}

.table-content__data__spec {
  @extend %fw-normal;

  display: block;
  width: 100%;
  padding-top: $spacing / 2;
  padding-right: 3rem;
  padding-bottom: $spacing / 2;
  text-align: left;

  small {
    opacity: 0.5;
  }

  @include mq(xl) {
    width: 32%;
    padding-left: $spacing;
    text-align: right;

    .is-solo & {
      width: 31%;
    }

    [class*='table-desktop--solo'] & {
      width: calc(100% - 35rem);
    }
  }
}

.table-content__data__value {
  @extend %text-center;
  @extend %fw-bold;

  display: block;
  width: 34%;
  padding: $spacing / 2;
  text-align: center;

  .is-solo & {
    width: 32%;

    @include mq(xl) {
      width: 23%;
    }
  }

  & + & {
    display: block;
    margin-left: 1rem;
  }

  ::v-deep small {
    display: block;
    opacity: 0.5;
  }

  [class*='table-desktop--'][class*='--solo'] & {
    width: 32%;
  }

  @include mq(xl) {
    [class*='table-desktop--'][class*='--solo'] & {
      width: 35rem;
    }
  }

  @include mq($until: xl) {
    background: rgba(200, 200, 200, 0.1);

    & + & {
      display: block;
      margin-left: 2%;
    }
  }

  @include mq($until: xl) {
    width: 49%;

    [class*='table-desktop--'][class*='--solo'] & {
      width: 100%;
    }
  }

  small {
    display: block;
    opacity: 0.5;
  }
}

legend,
th {
  line-height: 4rem;
}

td {
  padding: 0;
}

table table {
  border-collapse: collapse;
}

.table-content__order {
  display: flex;
  justify-content: flex-end;
  min-height: 7rem;
}

.table-content__order__button-outer {
  display: flex;
  justify-content: center;
  width: 50%;
  padding: 4rem 0;

  .is-solo & {
    width: 32%;

    @include mq(xl) {
      width: 22.5%;
    }
  }

  &:first-child {
    display: none;
  }

  [class*='table-desktop--solo'] & {
    width: 100%;

    @include mq(xl) {
      width: 35rem;
      // margin-right: 35%;
    }
  }

  @include mq(xl) {
    width: 34.5%;
  }
}
